import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import * as firebase from 'firebase/app';
import 'firebase/database';
import * as S3 from 'aws-sdk/clients/s3';
import { LesmillsRelease } from '../_models/lesmills-release';
import { LesmillsPlaylistSong } from '../_models/lesmills-playlist-song';
import { LesmillsProgram } from '../_models/lesmills-program';
import { LesmillsResource } from '../_models/lesmills-resource';

@Injectable({
  providedIn: 'root'
})
export class LesmillsService {

  database = firebase.database().ref().child('lesmills');

  constructor() { }

  getPrograms(): Observable<LesmillsProgram[]> {
    return new Observable((observer) => {
      this.database.on('value', (programs) => {
        const p = [];
        programs.forEach((program) => {
          p.push({
            ...program.toJSON(),
            ...{ key: program.key }
          });
        });
        p.sort((a, b) => a.name > b.name ? 1 : -1);
        observer.next(p);
      });
    });
  }

  getReleases(program): Observable<any> {
    return new Observable((observer) => {
      this.database.orderByChild('code').equalTo(program).on('value', (programs) => {
        if (programs.numChildren() === 1) {
          programs.forEach((p) => {
            this.database.child(p.key).child('releases').on('value', (releases) => {
              const r = [];
              releases.forEach((release) => {
                r.push({
                  ...release.toJSON(),
                  ...{ key: release.key },
                  ...{ programKey: p.key }
                });
              });
              observer.next(r);
            })
          });
        } else {
          observer.error(`no programs results or too much programs results (${programs.numChildren()}).`)
        }
      });
    });
  }

  getRelease(program, release): Observable<any> {
    return new Observable((observer) => {
      this.database.orderByChild('code').equalTo(program).on('value', (programs) => {
        if (programs.numChildren() === 1) {
          programs.forEach((p) => {
            this.database.child(p.key).child('releases').orderByChild('release').equalTo(release).on('value', (releases) => {
              if (releases.numChildren() === 1) {
                releases.forEach((rel) => {
                  this.database.child(p.key).child('releases').child(rel.key).on('value', (r) => {
                    observer.next({
                      ...r.toJSON(),
                      ...{ key: r.key },
                      ...{ programKey: p.key },
                      ...{ programName: p.val().name }
                    })
                  });
                });
              } else {
                observer.error(`no releases results or too much releases results (${releases.numChildren()}).`);
              }
            });
          });
        } else {
          observer.error(`no programs results or too much programs results (${programs.numChildren()}).`)
        }
      });
    });
  }

  createProgram(program: LesmillsProgram): Observable<any> {
    const key = this.database.push().key;

    return new Observable((observer) => {
      this.database.child(key).set(program).then(() => {
        observer.next(true);
        observer.complete();
      });
    });
  }

  addRelease(programKey: string, form): Observable<any> {
    const key = this.database.child(programKey + '/releases').push().key;

    const data: LesmillsRelease = {
      release: form.release,
      year: form.year,
      quarter: form.quarter
    };

    return new Observable((observer) => {
      this.database.child(programKey + '/releases/' + key).set(data).then(() => {

        form.playlist.forEach((song) => {
          this.addSong(programKey, key, song);
        });

        // Si se incluye music cover, se sube a Storage y se añade a base de datos.
        if (form.cover) {
          this.uploadCover(programKey, key, form.cover);
        }

        observer.next(true);
        observer.complete();
      });
    });
  }

  /**
   * Método para recibir la URL del recuerso en Firebase Storage.
   * @param programKey KEY del programa.
   * @param releaseKey KEY de la release.
   * @param resourceKey KEY del recurso.
   * @param resourceType Tipo de recurso.
   * @param resourceExtension Extensión del archivo.
   */
  // getResource(programKey, releaseKey, resourceKey, resourceType, resourceExtension): Observable<any> {
  //   return new Observable((observer) => {
  //     const path = 'lesmills/' + programKey + '/' + releaseKey + '/' + resourceType + '/' + resourceKey + '.' + resourceExtension;
  //     firebase.storage().ref(path).getDownloadURL().then((url) => {
  //       observer.next(url);
  //       observer.complete();
  //     });
  //   });
  // }

  /**
   * Método para recibir la URL del recurso en AWS S3.
   * @param programKey KEY del programa.
   * @param releaseKey KEY de la release.
   * @param resourceKey KEY del recurso.
   * @param resourceType Tipo de recurso.
   * @param resourceExtension Extensión del archivo.
   */
  getResourceUrl(programKey, releaseKey, resourceKey, resourceType, resourceExtension): Observable<any> {
    const bucket = new S3(
      {
        accessKeyId: environment.aws.accessKeyId,
        secretAccessKey: environment.aws.secretAccessKey,
        region: 'eu-west-1'
      }
    );
    const params = {
      Bucket: environment.aws.bucket, 
      Key: 'lesmills/' + programKey + '/' + releaseKey +  '/' + resourceType + '/' + resourceKey + '.' + resourceExtension
    };
    return new Observable((observer) => {
      bucket.getSignedUrl('getObject', params, (err, url) => {
        if (err) {
          observer.error(err);
        } else {
          observer.next(url);
        }
        observer.complete();
      });
    });
  }

  /**
   * Método para subir el archivo de una canción a Firebase Storage.
   * @param programKey KEY del programa.
   * @param releaseKey KEY de la release.
   * @param songKey KEY de la canción.
   * @param file Archivo a subir.
   */
  // uploadSong(programKey, releaseKey, songKey, file: File): Observable<any> {
  //   let extension: any = file.name.split('.');
  //   extension = extension[extension.length - 1];

  //   const path = 'lesmills/' + programKey + '/' + releaseKey +  '/song/' + songKey + '.' + extension;

  //   return new Observable((observer) => {
  //     firebase.storage().ref().child(path).put(file).on('state_changed', (event) => {
  //       observer.next(event);
  //     }, (error) => {
  //       observer.error(error);
  //     }, () => {
  //       this.database.child(programKey + '/releases/' + releaseKey + '/playlist').child(songKey).update({
  //         file: true
  //       });
  //       observer.next({ uploaded: true });
  //       observer.complete();
  //     });
  //   });
  // }

  /**
   * Método para subir el archivo de una canción a AWS S3.
   * @param programKey KEY del programa.
   * @param releaseKey KEY de la release.
   * @param songKey KEY de la canción.
   * @param file Archivo a subir.
   */
  uplaodSong(programKey, releaseKey, songKey, file: File): Observable<any> {
    let extension: any = file.name.split('.');
    extension = extension[extension.length - 1];

    const bucket = new S3(
      {
        accessKeyId: environment.aws.accessKeyId,
        secretAccessKey: environment.aws.secretAccessKey,
        region: 'eu-west-1'
      }
    );
    const params = {
      Bucket: environment.aws.bucket,
      Key: 'lesmills/' + programKey + '/' + releaseKey +  '/song/' + songKey + '.' + extension,
      Body: file,
      ACL: 'public-read',
      ContentType: file.type
    };

    return new Observable((observer) => {
      bucket.upload(params).on('httpUploadProgress', (evt) => {
          observer.next(evt);
      }).send((err, data) => {
          if (err) {
            observer.error(err);
          } else {
            observer.next(data);
            this.database.child(programKey + '/releases/' + releaseKey + '/playlist').child(songKey).update({
              file: true
            });
          }
          observer.complete();
      });
    });
  }

  uplaodVideo(programKey, releaseKey, file: File): Observable<any> {
    const key = this.database.child(programKey + '/releases/' + releaseKey + '/resources').push().key;

    let extension: any = file.name.split('.');
    extension = extension[extension.length - 1];

    const bucket = new S3(
      {
        accessKeyId: environment.aws.accessKeyId,
        secretAccessKey: environment.aws.secretAccessKey,
        region: 'eu-west-1'
      }
    );
    const params = {
      Bucket: environment.aws.bucket,
      Key: 'lesmills/' + programKey + '/' + releaseKey +  '/video/' + key + '.' + extension,
      Body: file,
      ACL: 'public-read',
      ContentType: file.type
    };

    return new Observable((observer) => {
      bucket.upload(params).on('httpUploadProgress', (evt) => {
          observer.next(evt);
      }).send((err, data) => {
          if (err) {
            observer.error(err);
          } else {
            observer.next(data);
            this.database.child(programKey + '/releases/' + releaseKey + '/resources').child(key).set({
              type: 'video',
              option: 'masterclass',
              extension,
              mimeType: file.type,
              size: file.size
            } as LesmillsResource);
          }
          observer.complete();
      });
    });
  }

  uplaodMusic(programKey, releaseKey, file: File): Observable<any> {
    const key = this.database.child(programKey + '/releases/' + releaseKey + '/resources').push().key;

    let extension: any = file.name.split('.');
    extension = extension[extension.length - 1];

    const bucket = new S3(
      {
        accessKeyId: environment.aws.accessKeyId,
        secretAccessKey: environment.aws.secretAccessKey,
        region: 'eu-west-1'
      }
    );
    const params = {
      Bucket: environment.aws.bucket,
      Key: 'lesmills/' + programKey + '/' + releaseKey +  '/music/' + key + '.' + extension,
      Body: file,
      ACL: 'public-read',
      ContentType: file.type
    };

    return new Observable((observer) => {
      bucket.upload(params).on('httpUploadProgress', (evt) => {
          observer.next(evt);
      }).send((err, data) => {
          if (err) {
            observer.error(err);
          } else {
            observer.next(data);
            this.database.child(programKey + '/releases/' + releaseKey + '/resources').child(key).set({
              type: 'music',
              option: 'pack',
              extension,
              mimeType: file.type,
              size: file.size
            } as LesmillsResource);
          }
          observer.complete();
      });
    });
  }

  setTrackDuration(programKey, releaseKey, songKey, duration) {
    this.database.child(programKey + '/releases/' + releaseKey + '/playlist/' + songKey).update({ duration });
  }

  getDownloadUrl(key) {
    const bucket = new S3(
      {
        accessKeyId: environment.aws.accessKeyId,
        secretAccessKey: environment.aws.secretAccessKey,
        region: 'eu-west-1'
      }
    );
    const params = {
      Bucket: environment.aws.bucket,
      Key: key,
      Expires: 60*5
    };
    return bucket.getSignedUrlPromise('getObject', params);
  }

  private addSong(programKey: string, releaseKey: string, song: LesmillsPlaylistSong) {
    const path = programKey + '/releases/' + releaseKey + '/playlist';
    const key = this.database.child(path).push().key;
    this.database.child(path).child(key).set(song);
  }

  /**
   * Función para subir a Firebase Storage el cover de tipo music y añadirlo a
   * la base de datos.
   * @param programKey Key del programa
   * @param releaseKey Key de la release
   * @param file Imagen del cover de tipo music
   */
  // private uploadCover(programKey, releaseKey, file: File) {
  //   const key = this.database.child(programKey + '/releases/' + releaseKey + '/resources').push().key;

  //   let extension: any = file.name.split('.');
  //   extension = extension[extension.length - 1];

  //   const path = 'lesmills/' + programKey + '/' + releaseKey +  '/cover/' + key + '.' + extension;

  //   firebase.storage().ref().child(path).put(file).then((resp) => {
  //     this.database.child(programKey + '/releases/' + releaseKey + '/resources').child(key).set({
  //       type: 'cover',
  //       option: 'music',
  //       extension,
  //       mimeType: file.type,
  //       size: file.size
  //     } as LesmillsResource);
  //   });
  // }

  /**
   * Método para subir a AWS S3 el cover de tipo music y añadirlo a la base de datos.
   * @param programKey KEY del programa.
   * @param releaseKey KEY de la release.
   * @param file Imagen del cover de tipo music.
   */
  private uploadCover(programKey, releaseKey, file: File) {
    const key = this.database.child(programKey + '/releases/' + releaseKey + '/resources').push().key;

    let extension: any = file.name.split('.');
    extension = extension[extension.length - 1];

    const bucket = new S3(
      {
        accessKeyId: environment.aws.accessKeyId,
        secretAccessKey: environment.aws.secretAccessKey,
        region: 'eu-west-1'
      }
    );
    const params = {
      Bucket: environment.aws.bucket,
      Key: 'lesmills/' + programKey + '/' + releaseKey +  '/cover/' + key + '.' + extension,
      Body: file,
      ACL: 'public-read',
      ContentType: file.type
    };

    bucket.upload(params, (err, data) => {
      if (!err) {
        this.database.child(programKey + '/releases/' + releaseKey + '/resources').child(key).set({
          type: 'cover',
          option: 'music',
          extension,
          mimeType: file.type,
          size: file.size
        } as LesmillsResource);
      }
    });
  }

  // EN CONSTRUCCIÓN
  // private uploadResource(file: File, programKey, releaseKey, resourceType, resourceOption?) {
  //   const key = this.database.child(programKey + '/releases/' + releaseKey + '/resources').push().key;

  //   let extension: any = file.name.split('.');
  //   extension = extension[extension.length - 1];

  //   const path = 'lesmills/' + programKey + '/' + releaseKey +  resourceType + key + '.' + extension;

  //   firebase.storage().ref().child(path).put(file).then((resp) => {
  //     this.database.child(programKey + '/releases/' + releaseKey + '/resources').child(key).set({
  //       type: resourceType,
  //       option: resourceOption,
  //       extension,
  //       mimeType: file.type,
  //       size: file.size
  //     } as LesmillsResource);
  //   });
  // }
}
