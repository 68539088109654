import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { LesmillsPlaylistSong } from 'src/app/_models/lesmills-playlist-song';
import { LesmillsService } from 'src/app/_services/lesmills.service';
import { Subscription } from 'rxjs';
import { fade } from 'src/app/animations';
import { LesmillsPlaylistSongTrackType } from 'src/app/_models/lesmills-playlist-song-track-type.enum';

@Component({
  selector: 'app-main-lesmills-admin-addrelease',
  templateUrl: './main-lesmills-admin-addrelease.component.html',
  styleUrls: ['./main-lesmills-admin-addrelease.component.scss'],
  animations: [fade]
})
export class MainLesmillsAdminAddreleaseComponent implements OnInit {

  programs;

  releaseForm: FormGroup;
  years = [];
  quarters = [
    { label: 'Primer trimestre', value: 1 },
    { label: 'Segundo trimestre', value: 2 },
    { label: 'Tercer trimestre', value: 3 },
    { label: 'Cuarto trimestre', value: 4 }
  ];

  playlistForm: FormGroup;
  trackLetter = [
    { label: 'A', value: 'a' },
    { label: 'B', value: 'b' },
    { label: 'C', value: 'c' }
  ];
  trackType = [
    { label: 'Original', value: 'original' },
    { label: 'Bonus', value: 'bonus' },
    { label: 'Alternativa', value: 'alternative' },
    { label: 'Express', value: 'express' },
    { label: 'Formato 45', value: 'format45' },
    { label: 'Mash Up', value: 'mashup' },
    { label: 'Mash It!', value: 'mashit' },
    { label: 'Outro', value: 'outro' }
  ];
  playlist;

  private lesmillsSubscriber: Subscription;

  constructor(
    private fb: FormBuilder,
    private lm: LesmillsService
  ) { }

  ngOnInit(): void {
    this.initForms();
    this.lesmillsSubscriber = this.lm.getPrograms().subscribe((resp) => {
      this.programs = resp;
    });
  }

  /**
   * Envía y guarda en la base de datos la nueva relese creada con
   * el formulario releaseForm.
   */
  sendRelease() {
    const programKey = this.releaseForm.controls.program.value.key;
    const release = {
      release: this.releaseForm.controls.release.value,
      year: this.releaseForm.controls.year.value,
      quarter: this.releaseForm.controls.quarter.value,
      cover: this.releaseForm.controls.cover.value,
      playlist: this.releaseForm.controls.playlist.value
    };
    this.lm.addRelease(programKey, release).subscribe(() => {
      this.resetReleaseForm();
    });
  }

  /**
   * Añade la canción al control playlist del formulario releaseForm.
   */
  addSong() {
    const playlist: LesmillsPlaylistSong[] = this.releaseForm.controls.playlist.value;
    playlist.push(this.playlistForm.value as LesmillsPlaylistSong);

    this.releaseForm.controls.playlist.setValue(playlist as LesmillsPlaylistSong[]);

    this.playlistForm.controls.track.setValue(this.playlistForm.controls.track.value + 1);
    this.playlistForm.controls.letter.setValue('a');
    this.playlistForm.controls.type.setValue('original');
    this.playlistForm.controls.title.reset();
    this.playlistForm.controls.artist.reset();

    this.parsePlaylist();
  }

  parsePlaylist() {
    const playlist: any = this.releaseForm.controls.playlist.value;

    const pl = [];

    playlist.forEach((song) => {
      const disc = song.disc || 'unknown';
      const discExists = pl.hasOwnProperty(disc);

      if (!discExists) {
        pl[disc] = [];
      }

      const s = {
        track: song.track,
        letter: song.letter,
        type: song.type,
        title: song.title,
        artist: song.artist
      };

      s.type = LesmillsPlaylistSongTrackType[s.type];

      const more = playlist.filter((f) => f.track === s.track && (f.letter !== s.letter && f.letter !== undefined));

      // Si no hay más tracks con otras letras, la letra no se muestra.
      if (more.length === 0) {
        s.letter = undefined;
      }

      pl.sort((a, b) => {
        if (a.track > b.track) { return 1; }
        if (a.track > b.track) { return -1; }
        if (a.letter > b.letter) { return 1; }
        if (a.letter < b.letter) { return -1; }
        return 0;
      });

      pl[disc].push(s);
    });

    this.playlist = pl;
  }

  /**
   * Función que se lanza al pulsar sobre el cover del formulario releaseForm.
   */
  onClickCover() {
    document.getElementById('cover').click();
  }

  /**
   * Prepara la imágen que se adjuntará al crear una release en releaseForm.
   * @param file Imágen a subir.
   */
  prepareCover(file) {
    const reader = new FileReader();
    reader.onload = (e) => {
      document.getElementsByClassName('image')[0].setAttribute('style', 'background-image: url(' + e.target.result + ');');
    };
    reader.readAsDataURL(file);

    // Se asigna el valor a cover en el formulario.
    this.releaseForm.controls.cover.setValue(file);
  }

  private initForms() {
    this.releaseForm = this.fb.group({
      program: new FormControl(undefined, Validators.required),
      release: new FormControl(undefined, Validators.required),
      year: new FormControl(undefined, Validators.required),
      quarter: new FormControl(undefined, Validators.required),
      cover: new FormControl(undefined),
      playlist: new FormControl([] as LesmillsPlaylistSong[])
    });
    this.setYears();

    this.playlistForm = this.fb.group({
      track: new FormControl(1, Validators.required),
      letter: new FormControl('a'),
      type: new FormControl('original'),
      disc: new FormControl(undefined),
      title: new FormControl(undefined, Validators.required),
      artist: new FormControl(undefined, Validators.required)
    });
  }

  /**
   * Genera el objeto del dropdown Año del formulario releaseForm.
   */
  private setYears() {
    for (let i = new Date().getFullYear(); i >= 1990; i--) {
      this.years.push({ label: i, value: i });
    }
  }

  /**
   * Resetea el formulario de añadir nuevo release.
   */
  private resetReleaseForm() {
    this.releaseForm.reset();
    this.releaseForm.controls.playlist.setValue([]);
    document.getElementsByClassName('image')[0].removeAttribute('style');
  }

}
