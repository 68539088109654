import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/remote-config';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor() { }

  auth(): Observable<any> {
    return new Observable((observer) => {
      firebase.auth().onAuthStateChanged((auth) => {
        if (auth) {
          observer.next(auth);
        } else {
          observer.error(true);
          observer.complete();
        }
      });
    });
  }

  getLoggedUser() {
    return firebase.auth().currentUser;
  }

  setLang(lang) {
    firebase.auth().languageCode = lang;
  }

  validation(phone, recaptcha): Observable<any> {
    return new Observable((observer) => {
      firebase.database().ref().child('users').once('value', (data) => {
        const users = [];
        data.forEach((user) => {
          users.push(user.val());
        });

        firebase.database().ref().child('config').child('lesmills').once('value', (config) => {
          if (config.val()['verify_user_exists']) {
            console.log('Verificando si existe el usuario.');
            const exists = users.find((f) => { return f.profile.phoneNumber === phone && f.services.lesmills === true });

            if (exists) {
              firebase.auth().signInWithPhoneNumber(phone, recaptcha).then((resp) => {
                observer.next(resp);
                observer.complete();
              }, (error) => {
                observer.error(error);
                observer.complete();
              }).catch((error) => {
                observer.error(error);
                observer.complete();
              });
            } else {
              observer.error('user-not-exists');
            }
          } else {
            console.log('No se verifica si existe el usuario.');
            firebase.auth().signInWithPhoneNumber(phone, recaptcha).then((resp) => {
              observer.next(resp);
              observer.complete();
            }, (error) => {
              observer.error(error);
              observer.complete();
            }).catch((error) => {
              observer.error(error);
              observer.complete();
            });
          }
        });
      }, (error) => {
        observer.error(error);
        observer.complete();
      });
    });
  }

  verify(confirmation, code): Observable<any> {
    return new Observable((observer) => {
      confirmation.confirm(code).then((result) => {
        if (result.additionalUserInfo.isNewUser) {
          firebase.database().ref().child('users').child(result.user.uid).set({
            profile: {
              phoneNumber: result.user.phoneNumber
            }
          });
        }
        observer.next(result);
        observer.complete();
      }, (error) => {
        observer.next(error);
        observer.complete();
      });
    });
  }

  singOut() {
    firebase.auth().signOut();
  }
}
