import { Component, OnInit } from '@angular/core';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import { Router } from '@angular/router';
import { LoginService } from '../_services/login.service';
import { fade, loginLogo } from '../animations';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [fade, loginLogo]
})
export class LoginComponent implements OnInit {

  loading = {
    document: false
  };
  recaptcha;
  phone;
  confirmation;
  user;

  constructor(
    private router: Router,
    private loginService: LoginService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.loginService.setLang('es');
    this.recaptcha = new firebase.auth.RecaptchaVerifier('recaptcha-container', { size: 'invisible' });
  }

  getValCode(form) {
    this.loading.document = true;
    const verify = this.recaptcha;
    this.loginService.validation(form.value.phone, verify).subscribe((success) => {
      this.confirmation = success;
      this.loading.document = false;
    }, (error) => {
      this.loading.document = false;
      console.warn(error);

      if (error === 'user-not-exists') {
        this.error('El usuario no existe.')
      } else {
        this.error('Ha habido un error inesperado. Por favor, vuelve a intentarlo pasado unos minutos o contacta con nosotros.')
      }
    });
  }

  verifyCode(form) {
    this.loading.document = true;
    this.loginService.verify(this.confirmation, form.value.code).subscribe((result) => {
      this.user = result;
      this.loading.document = false;
      this.router.navigate(['']);
    }, (error) => {
      console.error(error);
      this.loading.document = false;
    });
  }

  error(error) {
    this.messageService.add({
      key: 'login',
      severity: 'error',
      summary: 'Error',
      detail: error
    });
  }

}
