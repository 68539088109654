import { Component, OnInit } from '@angular/core';
import { AppService } from './_services/app.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  background;

  constructor(
    private appService: AppService
  ){ }

  ngOnInit(): void {
    this.appService.dayMode().subscribe((mode) => {
      if (mode !== this.background) {
        const body = document.getElementsByTagName('body')[0].classList;
        body.remove(this.background);
        body.add(mode);
        this.background = mode;
      }
    });
  }
}
