import { Directive, HostListener, Input } from '@angular/core';
import { MusicPlayerService } from './music-player.service';

@Directive({
  selector: '[musicPlayer]'
})
export class MusicPlayerDirective {
  @Input() musicPlayer: string;
  @Input() song: any;
  @Input() songs: any;

  constructor(private _musicPlayerService: MusicPlayerService) {}

  @HostListener('click', ['$event']) onClick() {
    if (this._musicPlayerService.isPlaying && this._musicPlayerService.currentTrack === this.song.id) {
      this._musicPlayerService.pause();
    } else if(!this._musicPlayerService.isPlaying && this._musicPlayerService.currentTrack === this.song.id) {
      this._musicPlayerService.play();
    } else {
      this._musicPlayerService.stop();

      let trackId: any;
      if (this.songs) {
        for(let i = 0; i < this.songs.length; i++) {
          if (this.songs[i].url) {
            this._musicPlayerService.addTrack(this.songs[i]);
          }
        }

        if(this.musicPlayer === 'play') {
          if (this.song) {
            this._musicPlayerService.playTrack(this.song.id);
          } else {
            this._musicPlayerService.play();
          }
        }
      } else {
        trackId = this._musicPlayerService.addTrack(this.song);

        if(this.musicPlayer === 'play') {
          this._musicPlayerService.playTrack(trackId);
        }
      }
    }
  }
}
