import { Component, OnInit, OnDestroy } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Subscription } from 'rxjs';
import { LesmillsService } from 'src/app/_services/lesmills.service';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { fade, viewTransition } from 'src/app/animations';

import { LesmillsPlaylistSong } from '../../_models/lesmills-playlist-song';
import { LesmillsPlaylistSongTrackType } from '../../_models/lesmills-playlist-song-track-type.enum';
import { LesmillsRelease } from 'src/app/_models/lesmills-release';
import { LesmillsProgram } from 'src/app/_models/lesmills-program';
import { Tools } from 'src/app/_models/tools';
import { KeyValuePipe } from '@angular/common';
import { UsersService } from 'src/app/_services/users.service';
import { MessageService } from 'primeng/api';
import { MusicPlayerComponent } from '../_layout/music-player/music-player.component';
import { MusicPlayerService } from 'src/app/_utils/music/music-player.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-main-lesmills',
  templateUrl: './main-lesmills.component.html',
  styleUrls: ['./main-lesmills.component.scss'],
  providers: [KeyValuePipe],
  animations: [fade, viewTransition]
})
export class MainLesmillsComponent implements OnInit, OnDestroy {

  admin: boolean;

  selectedProgram;

  leftTools: Tools[];
  programs: LesmillsProgram[];

  modal: boolean;

  programForm: FormGroup;

  private activatedRouteSubscriber: Subscription;
  private lesmillsSubscriber: Subscription;
  private usersSubscriber: Subscription;

  constructor(
    public musicPlayer: MusicPlayerService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private usersService: UsersService,
    private lesmillService: LesmillsService
  ) { }

  ngOnInit(): void {
    this.setBackground();

    this.activatedRouteSubscriber = this.activatedRoute.url.subscribe(() => {
      this.selectedProgram = this.router.url.split('/')[2];
    });

    // Controlamos si el usuario es admin o no para mostrar las herramientas
    // de administración.
    this.usersSubscriber = this.usersService.getUserInfo().subscribe((user) => {
      if (user.admin) {
        this.admin = true;
        this.leftTools = [
          { identifier: 'create', order: 1, position: 'right', name: 'Crear programa', icon: 'add' },
          { identifier: 'add', order: 1, position: 'right', name: 'Añadir release', icon: 'add_box' }
        ];
        this.initForms();
      } else {
        this.admin = false;
        this.leftTools = undefined;
      }
    });

    // Suscripción para cambios en base de datos.
    this.lesmillsSubscriber = this.lesmillService.getPrograms().subscribe((resp) => {
      this.programs = resp;
    });
  }

  ngOnDestroy() {
    const body = document.getElementsByTagName('body')[0].classList;
    body.remove('lesmills-background');

    this.lesmillsSubscriber?.unsubscribe();
    this.usersSubscriber?.unsubscribe();
    this.activatedRouteSubscriber?.unsubscribe();
  }

  goProgram(program) {
    this.router.navigate([program]);
  }

  /**
   * Acción que ejerce la herramienta del menu izquierdo que se pulsa.
   * @param event Herramienta que se ha pulsado.
   */
  toolsAction(event) {
    switch (event) {
      case 'add':
        this.router.navigate(['admin', 'addrelease']);
        break;
      case 'create':
        this.modal = true;
        break;
      default:
        break;
    }
  }

  createProgram() {
    this.lesmillService.createProgram(this.programForm.value).subscribe(() => {
      this.modal = false;
      this.programForm.reset();
    });
  }

  private initForms() {
    this.programForm = this.fb.group({
      name: new FormControl(undefined, Validators.required),
      code: new FormControl(undefined, Validators.required),
      color: new FormControl(undefined, Validators.required)
    });
  }

  /**
   * Cambia la imágen del fondo de la aplicación al entrar a Lesmills.
   */
  private setBackground() {
    const body = document.getElementsByTagName('body')[0].classList;
    body.add('lesmills-background');
  }
}
