import { Component, OnInit, Input } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Menu } from '../../_models/menu';
import { TooltipPositions } from '../../_models/tooltip-positions.enum';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  @Input() menu: Menu[];
  page: string;
  options: {
    position: 'left' | 'right' | 'bottom',
    menu: Menu[],
    tooltip: TooltipPositions
  }[];

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
    this.generateMenu();
    this.page = '/' + this.router.url.split('/')[1];
    this.router.events.subscribe((route) => {
      if (route instanceof NavigationEnd) {
        this.page = '/' + this.router.url.split('/')[1];
      }
    });
  }

  filterMenu(position: 'left' | 'right' | 'bottom') {
    return this.menu.filter((f) => f.position === position).sort((a, b) => a.order > b.order ? 1 : -1);
  }

  generateMenu() {
    const positions: any['left' | 'right' | 'bottom'] = ['left', 'right', 'bottom'];
    const options = [];
    positions.forEach((position) => {
      if (this.filterMenu(position).length !== 0) {
        options.push({ position, menu: this.filterMenu(position), tooltip: TooltipPositions[position] });
      }
    });
    this.options = options;
  }

}
