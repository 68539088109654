<p-toast position="top-right" [style]="{top: '10px'}" key="login"></p-toast>
<div id="recaptcha-container"></div>
<div class="login-container">
    <div class="logo" *ngIf="!loading.document && !confirmation" [@loginLogo]>lesmills</div>
    <div class="login-content" *ngIf="!loading.document" [@fade]>
        <div *ngIf="!confirmation" class="p-fluid">
            <h1>Hola.</h1>
            <p style="margin-top: 0;">Para iniciar sesión, introduce tu número de teléfono móvil. Recibirás un código
                por sms que deberás introducir luego.
            </p>
            <form #phoneForm="ngForm" (ngSubmit)="getValCode(phoneForm)">
                <div class="p-inputgroup">
                    <input type="text" pInputText ngModel name="phone" placeholder="+34666666666" autocomplete="off"> 
                    <button pButton type="submit" icon="pi pi-arrow-right" [disabled]="!phoneForm.value.phone"></button>    
                </div>
            </form>
        </div>
        <div *ngIf="confirmation" class="p-fluid">
            <p style="margin-top: 34px;">Introduce el código que recibiste por sms al teléfono que indicaste anteriormente.
            </p>
            <form #codeForm="ngForm" (ngSubmit)="verifyCode(codeForm)">
                <div class="p-inputgroup">
                    <input type="text" pInputText ngModel name="code" placeholder="Código sms" autocomplete="off"> 
                    <button pButton type="submit" icon="pi pi-arrow-right" [disabled]="!codeForm.value.code"></button> 
                </div>
            </form>
        </div>
    </div>
</div>

<p-blockUI [blocked]="loading.document">
    <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="5"></p-progressSpinner>
</p-blockUI>