<p-toast position="top-right"></p-toast>
<div class="container">
    <div class="content">
        <app-left-content>
            <div class="image"><i class="mi">face</i></div>
        </app-left-content>
        <app-right-content>
            <ng-container *ngIf="form">
                <form [formGroup]="form" (ngSubmit)="form.valid ? saveData() : null">
                    <div class="form">
                        <div class="p-grid content">
                            <div class="p-xl-12 p-lg-12 p-md-12 p-sm-12">
    
                                <h2>Datos personales</h2>
                                
                                <div class="p-grid p-fluid">
                                    <div class="p-xl-3 p-lg-4 p-md-2 label">Nombre</div>
                                    <div class="p-xl-6 p-lg-8 p-md-10"><input type="text" formControlName="name" pInputText placeholder="Nombre"></div>
                                </div>
    
                                <div class="p-grid p-fluid">
                                    <div class="p-xl-3 p-lg-4 p-md-2 label">Apellidos</div>
                                    <div class="p-xl-6 p-lg-8 p-md-10"><input type="text" formControlName="surname" pInputText placeholder="Apellidos"></div>
                                </div>
    
                                <div class="p-grid p-fluid">
                                    <div class="p-xl-3 p-lg-4 p-md-2 label">
                                        <i class="mi" pTooltip="Por defecto, tus contactos verán el apodo en vez de tu nombre completo en los chats." tooltipPosition="bottom">info</i>
                                        Apodo*
                                    </div>
                                    <div class="p-xl-6 p-lg-8 p-md-10">
                                        <input type="text" formControlName="short" pInputText pKeyFilter="alpha" placeholder="Apodo" required>
                                        <div class="input-error" [@fade] *ngIf="form.controls.short.errors?.required">
                                            Este campo es obligatorio.
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="p-grid">
                                    <div class="p-xl-3 p-lg-4 p-md-2 label">Género*</div>
                                    <div class="p-xl-6 p-lg-8 p-md-10"><p-selectButton [options]="[{ label: 'Hombre', value: 1 }, { label: 'Mujer', value: 2 }]" formControlName="genre" required></p-selectButton></div>
                                </div>
    
                                <h2>Datos de contacto</h2>
    
                                <div class="p-grid p-fluid">
                                    <div class="p-xl-3 p-lg-4 p-md-2 label">
                                        Nombre de usuario*
                                    </div>
                                    <div class="p-xl-6 p-lg-8 p-md-10">
                                        <input type="text" pKeyFilter="alphanum" formControlName="nickname" pInputText placeholder="Usuario">
                                        <div class="input-error"
                                             [@fade]
                                             *ngIf="form.controls.nickname.errors?.minlength || form.controls.nickname.errors?.required">
                                             {{ form.controls.nickname.errors?.minlength ? 'El nombre de usuario debe ser de al menos 4 carácteres.' :
                                             form.controls.nickname.errors?.required ? 'Este campo es obligatorio.' : '' }}
                                        </div>
                                    </div>
                                </div>
    
                                <div class="p-grid p-fluid">
                                    <div class="p-xl-3 p-lg-4 p-md-2 label">Teléfono móvil*</div>
                                    <div class="p-xl-6 p-lg-8 p-md-10"><input type="text" formControlName="phoneNumber" pInputText mask="+34 999 999 999" pKeyFilter="alpha" placeholder="Teléfono móvil"></div>
                                </div>
    
                                <div class="p-grid p-fluid">
                                    <div class="p-xl-3 p-lg-4 p-md-2 label">
                                        Correo electrónico
                                    </div>
                                    <div class="p-xl-6 p-lg-8 p-md-10">
                                        <input type="text" formControlName="email" pInputText placeholder="Correo electrónico">
                                        <div class="input-error" [@fade] *ngIf="form.controls.email.errors?.email">
                                            El formato de correo electrónico no es correcto.
                                        </div>
                                    </div>
                                </div>
    
                            </div>
                        </div>
                    </div>
    
                    <div class="form-submit">
                        <button pButton type="submit" label="Guardar" [disabled]="form.invalid"></button>
                    </div>
    
                </form>
            </ng-container>
        </app-right-content>
    </div>
</div>