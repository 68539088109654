import { Component, OnInit, OnDestroy } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { LesmillsService } from 'src/app/_services/lesmills.service';
import { KeyValuePipe } from '@angular/common';
import { LesmillsPlaylistSongTrackType } from 'src/app/_models/lesmills-playlist-song-track-type.enum';
import { Subscription } from 'rxjs';
import { UsersService } from 'src/app/_services/users.service';
import { MessageService, MenuItem } from 'primeng/api';
import { MusicPlayerService } from 'src/app/_utils/music/music-player.service';

@Component({
  selector: 'app-main-lesmills-release',
  templateUrl: './main-lesmills-release.component.html',
  styleUrls: ['./main-lesmills-release.component.scss']
})
export class MainLesmillsReleaseComponent implements OnInit, OnDestroy {

  admin: boolean;
  adminMenu: MenuItem[] = [
    { 
      label: 'Añadir vídeo',
      command: () => {
        this.selectVideoFile();
      }
    }, 
    { 
      label: 'Añadir música',
      command: () => {
        this.selectMusicFile();
      }
    }
  ];

  release;
  playlist;

  videoModal: boolean;
  video;

  downloads: {
    music,
    video
  };
  downloadsMenu: MenuItem[] = [];

  private usersSubscriber: Subscription;
  private lmSubscriber: Subscription;
  private musicPlayerTrackInfoSubscriber: Subscription;

  constructor(
    public musicPlayer: MusicPlayerService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private keyvaluePipe: KeyValuePipe,
    private messageService: MessageService,
    private lm: LesmillsService,
    private usersService: UsersService
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((p) => {
      // Para buscar la release en la BD, se compara valor y tipo.
      // Puede haber release 1-2, que son string, así que se tiene que pasar a
      // tipo number si no es string para que la comparación en BD sea correcta.
      const isString = p.release.replace(/(\d)+/g, '') !== '';
      const releaseNumber = isString ? p.release : Number.parseInt(p.release);

      this.lmSubscriber = this.lm.getRelease(p.program, releaseNumber).subscribe((release) => {
        this.release = release;
        this.getCover();
        this.getVideo();
        this.getDownloads();
        // this.getPlaylist();
        this.getPlaylistByDisc();
      }, () => {
        this.router.navigate([p.program]);
      });
    });

    this.usersSubscriber = this.usersService.getUserInfo().subscribe((user) => {
      if (user.admin) {
        this.admin = true;
        this.setTrackDuration();
      } else {
        this.admin = false;
        this.musicPlayerTrackInfoSubscriber?.unsubscribe();
      }
    });
  }

  ngOnDestroy() {
    this.usersSubscriber?.unsubscribe();
    this.lmSubscriber?.unsubscribe();
  }

  getCover() {
    const resources: any = this.keyvaluePipe.transform(this.release.resources);
    if (resources) {
      const cover = resources.find((f) => f.value.type === 'cover' && f.value.option === 'music');
      if (cover) {
        this.release.cover =
          environment.aws.bucketUrl + 
          'lesmills/' +
          this.release.programKey + '/' +
          this.release.key + '/cover/' +
          cover.key + '.' + cover.value.extension;
      }
    }
  }

  getVideo() {
    const resources: any = this.keyvaluePipe.transform(this.release.resources);
    if (resources) {
      const video = resources.find((f) => f.value.type === 'video' && f.value.option === 'masterclass');
      if (video) {
        this.video = {
          url: environment.aws.bucketUrl + 
          'lesmills/' +
          this.release.programKey + '/' +
          this.release.key + '/video/' +
          video.key + '.' + video.value.extension,
          mimeType: video.value.mimeType
        };
      }
    }
  }

  /**
   * Opción temporal para la descarga de recursos
   */
  getDownloads() {
    this.downloadsMenu = [];
    const resources: any = this.keyvaluePipe.transform(this.release.resources);
    const music = resources.find((f) => f.value.type === 'music' && f.value.option === 'pack');
    const video = resources.find((f) => f.value.type === 'video' && f.value.option === 'masterclass');

    if (music) {
      this.downloadsMenu.push(
        {
          label:'Descargar música',
          command: () => {
            this.getDownload('music', 'pack');
          }
        }
      );
    }

    // if (video) {
    //   this.downloadsMenu.push(
    //     {
    //       label:'Descargar vídeo',
    //       command: () => {
    //         this.getDownload('video', 'masterclass');
    //       }
    //     }
    //   );
    // }
  }

  getDownload(type, option) {
    const resources: any = this.keyvaluePipe.transform(this.release.resources);
    if (resources) {
      const resource = resources.find((f) => f.value.type === type && f.value.option === option);
      if (resource) {
        const key = 'lesmills/' +
        this.release.programKey + '/' +
        this.release.key + '/' + type + '/' +
        resource.key + '.' + resource.value.extension;

        this.lm.getDownloadUrl(key).then((url) => {
          this.download(url, resource.value.extension);
        });
      }
    }
  }

  download(url, extension) {
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.setAttribute('style', 'display: none');
      a.href = url;
      a.download = this.release.programName + '-' + this.release.release + '-music.' + extension;
      a.click();
      a.remove();
      console.log('file download:', url);
  }

  getPlaylist() {
    const playlist: any = this.keyvaluePipe.transform(this.release.playlist);

    const pl = [];

    playlist.forEach((song) => {
      const s: any = song.value;

      s.id = song.key;
      s.cover = this.release.cover;
      s.type = LesmillsPlaylistSongTrackType[s.type];

      s.program = this.release.programKey;
      s.release = this.release.key;

      const more = playlist.filter((f) => f.value.track === s.track && (f.value.letter !== s.letter && f.value.letter !== undefined));

      // Si no hay más tracks con otras letras, la letra no se muestra.
      if (more.length === 0) {
        s.letter = undefined;
      }

      if (song.value.file) {
        s.url = environment.aws.bucketUrl + 'lesmills/' +
                  this.release.programKey + '/' +
                  this.release.key + '/song/' +
                  song.key + '.mp3';
        s.album = this.release.programName + ' ' + this.release.release;
      }

      pl.sort((a, b) => {
        if (a.track > b.track) { return 1; }
        if (a.track > b.track) { return -1; }
        if (a.letter > b.letter) { return 1; }
        if (a.letter < b.letter) { return -1; }
        return 0;
      });

      pl.push(s);
    });

    this.playlist = pl;
  }

  getPlaylistByDisc() {
    const playlist: any = this.keyvaluePipe.transform(this.release.playlist);

    const pl = [];

    playlist.forEach((song) => {
      const disc = song.value.disc || 'unknown';
      const discExists = pl.hasOwnProperty(disc);

      if (!discExists) {
        pl[disc] = [];
      }

      const s: any = song.value;

      s.id = song.key;
      s.cover = this.release.cover;
      s.type = LesmillsPlaylistSongTrackType[s.type];

      s.program = this.release.programKey;
      s.release = this.release.key;

      const more = playlist.filter((f) => f.value.track === s.track && (f.value.letter !== s.letter && f.value.letter !== undefined));

      // Si no hay más tracks con otras letras, la letra no se muestra.
      if (more.length === 0) {
        s.letter = undefined;
      }

      if (song.value.file) {
        s.url = environment.aws.bucketUrl + 'lesmills/' +
                  this.release.programKey + '/' +
                  this.release.key + '/song/' +
                  song.key + '.mp3';
        s.album = this.release.programName + ' ' + this.release.release;
      }

      pl.sort((a, b) => {
        if (a.track > b.track) { return 1; }
        if (a.track > b.track) { return -1; }
        if (a.letter > b.letter) { return 1; }
        if (a.letter < b.letter) { return -1; }
        return 0;
      });

      pl[disc].push(s);
    });

    this.playlist = pl;
  }

  selectSongFile(songId) {
    document.getElementById(songId).click();
  }

  /**
   * Función para subir el archivo mp3 a Firebase Storage.
   * @param file Archivo a subir.
   * @param songId ID (key) de la canción.
   */
  uploadSong(file, songId) {
    this.lm.uplaodSong(this.release.programKey, this.release.key, songId, file).subscribe((resp) => {
      console.log(resp);
      if (resp.ETag) {
        this.messageService.add({
          key: 'document',
          severity: 'success',
          summary: 'Archivo cargado',
          detail: 'Se ha cargado correctamente el archivo.'
        });
      }
    });
  }

  selectVideoFile() {
    document.getElementById('video').click();
  }

  /**
   * Función para subir el archivo mp3 a Firebase Storage.
   * @param file Archivo a subir.
   * @param songId ID (key) de la canción.
   */
  uploadVideo(file) {
    this.lm.uplaodVideo(this.release.programKey, this.release.key, file).subscribe((resp) => {
      console.log(resp);
      if (resp.ETag) {
        this.messageService.add({
          key: 'document',
          severity: 'success',
          summary: 'Archivo cargado',
          detail: 'Se ha cargado correctamente el archivo.'
        });
      }
    });
  }

  selectMusicFile() {
    document.getElementById('music').click();
  }

  /**
   * Función para subir el archivo mp3 a Firebase Storage.
   * @param file Archivo a subir.
   */
  uploadMusic(file) {
    this.lm.uplaodMusic(this.release.programKey, this.release.key, file).subscribe((resp) => {
      console.log(resp);
      if (resp.ETag) {
        this.messageService.add({
          key: 'document',
          severity: 'success',
          summary: 'Archivo cargado',
          detail: 'Se ha cargado correctamente el archivo.'
        });
      }
    });
  }

  /**
   * SOLO PARA ADMINS. Setea en base de datos la duración de la canción
   * que está sonando. Cuando se pausa la canción, se envía el dato a la
   * base de datos.
   */
  private setTrackDuration() {
    this.musicPlayerTrackInfoSubscriber = this.musicPlayer.musicPlayerEventEmitter
      .subscribe((event: any) => {
        if (event.event === 'music:isPlaying' && !this.musicPlayer.isPlayingStatus()) {
          const song = this.musicPlayer.currentTrackData();
          this.lm.setTrackDuration(song.program, song.release, song.id, this.musicPlayer.duration);
        }
      });
  }

}
