import { trigger, state, style, transition, animate, query } from '@angular/animations';

export const viewTransition = trigger('viewTransition', [
  transition('* => *', [
    query(
      ':enter',
      [style({ opacity: 0 })],
      { optional: true }
    ),
    query(':leave', [
      style({
        opacity: 1
      }),
      animate('250ms ease-in-out', style({ opacity: 0 }))
    ],
    { optional: true }),
    query(':enter', [
      style({
        opacity: 0
      }),
      animate('250ms ease-in-out', style({ opacity: 1 }))
    ],
    { optional: true })
  ])
]);

export const fade = trigger('fade', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate('250ms ease', style({ opacity: 1 }))
  ]),
  transition(':leave', [
    style({ opacity: 1 }),
    animate('250ms ease', style({ opacity: 0 }))
  ])
]);

export const loginLogo = trigger('loginLogo', [
  transition(':enter', [
    style({ opacity: 0, top: 'calc(50% - 90px)' }),
    animate('500ms 500ms ease-out', style({ opacity: 1, top: 'calc(50% - 133px)' }))
  ]),
  transition(':leave', [
    style({ opacity: 1 }),
    animate('250ms ease', style({ opacity: 0 }))
  ])
]);

export const mainLogo = trigger('mainLogo', [
  transition(':enter', [
    style({ opacity: 0, top: '-40px' }),
    animate('500ms 500ms ease-out', style({ opacity: 1, top: '0' }))
  ]),
  transition(':leave', [
    style({ opacity: 1 }),
    animate('250ms ease', style({ opacity: 0 }))
  ])
]);

export const musicPlayer = trigger('musicPlayer', [
  transition(':enter', [
    style({ opacity: 0, top: '-75px' }),
    animate('250ms ease', style({ opacity: 1, top: '0' }))
  ]),
  transition(':leave', [
    style({ opacity: 1, top: '0' }),
    animate('250ms ease', style({ opacity: 0, top: '-75px' }))
  ])
]);
