export enum LesmillsPlaylistSongTrackType {
    original = 'original',
    bonus = 'BNS',
    alternative = 'ALT',
    express = 'EXP',
    format45 = 'F45',
    mashup = 'MU',
    mashit = 'MI',
    outro = 'OUT',
    other = 'other'
}
