<div class="container" *ngIf="playlist.length > 0" [@musicPlayer]>
    <music-progress-bar [progress]="progress" [loading]="loading"></music-progress-bar>
    <div class="cover" [style.background-image]="currentPlaying?.cover ? 'url(' + currentPlaying?.cover + ')' : 'url(../../../assets/images/noimage.png)'"></div>
    <div class="tools">
        <div class="controls">
            <i class="mi filled" previousTrack>skip_previous</i>
            <i class="mi filled" stopMusic clearPlaylist>stop</i>
            <i class="mi filled" playPauseMusic>{{ isPlaying ? 'pause' : 'play_arrow' }}</i>
            <i class="mi filled" nextTrack>skip_next</i>
        </div>
        <div class="info">
            <div class="title">{{ currentPlaying?.track }}{{ currentPlaying?.letter | uppercase }}. {{ currentPlaying?.title }}</div>
            <div class="artist">{{ currentPlaying?.artist }} — {{ currentPlaying?.album }}</div>
        </div>
    </div>
</div>