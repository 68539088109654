<div class="container">
    <div class="content" data-simplebar>
        <ng-content></ng-content>
    </div>
    <ng-container *ngIf="_tools && _tools.length !== 0">
        <div class="tools">
            <ng-container class="left" *ngFor="let option of options">
                <i *ngFor="let tool of option.tools"
                   class="mi"
                   [ngClass]="option.position"
                   [pTooltip]="tool.name"
                   [tooltipPosition]="option.tooltip"
                   (click)="actionClick(tool.identifier)">
                    {{ tool.icon }}
                </i>
            </ng-container>
        </div>
    </ng-container>
    
</div>