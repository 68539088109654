import { Component, OnInit, OnDestroy } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { LesmillsService } from 'src/app/_services/lesmills.service';
import { ActivatedRoute, Router } from '@angular/router';
import { KeyValuePipe } from '@angular/common';
import { Subscription } from 'rxjs';
import { UsersService } from 'src/app/_services/users.service';

@Component({
  selector: 'app-main-lesmills-program',
  templateUrl: './main-lesmills-program.component.html',
  styleUrls: ['./main-lesmills-program.component.scss']
})
export class MainLesmillsProgramComponent implements OnInit, OnDestroy {

  admin: boolean;

  program;
  releases = [];

  private usersSubscriber: Subscription;
  private lmSubscriber: Subscription;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private keyvaluePipe: KeyValuePipe,
    private lm: LesmillsService,
    private usersService: UsersService
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((p) => {
      this.program = p.program;
      this.lmSubscriber = this.lm.getReleases(p.program).subscribe((releases) => {
        this.releases = releases;
        this.setCovers();
        this.releases = this.releases.sort((a, b) => {
          if (a.year === b.year) {
            if (a.quarter > b.quarter) {
              return -1;
            } else if (a.quarter < b.quarter) {
              return 1;
            }
          } else {
            return (a.year > b.year) ? -1 : 1;
          }
        });
      }, () => {
        this.router.navigate(['']);
      });
    });

    this.usersSubscriber = this.usersService.getUserInfo().subscribe((user) => {
      if (user.admin) {
        this.admin = true;
      } else {
        this.admin = false;
      }
    });
  }

  ngOnDestroy() {
    this.usersSubscriber?.unsubscribe();
    this.lmSubscriber?.unsubscribe();
  }

  /**
   * Consulta y muestra los covers en su correspondiente release al
   * mostrar la lista de releases.
   */
  setCovers() {
    for (const [i, release] of this.releases.entries()) {
      const resources: any = this.keyvaluePipe.transform(release.resources);
      if (resources) {
        const cover = resources.find((f) => f.value.type === 'cover' && f.value.option === 'music');
        if (cover) {
          this.releases[i].cover =
            environment.aws.bucketUrl + 
            'lesmills/' +
            release.programKey + '/' +
            release.key + '/cover/' +
            cover.key + '.' + cover.value.extension;
        }
      }
    }
  }

  goRelease(releaseNumber) {
    this.router.navigate([this.program, releaseNumber]);
  }
}