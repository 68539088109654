import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { UsersService } from 'src/app/_services/users.service';
import { Subscription } from 'rxjs';
import { MessageService } from 'primeng/api';
import { fade } from 'src/app/animations';

@Component({
  selector: 'app-main-profile',
  templateUrl: './main-profile.component.html',
  styleUrls: ['./main-profile.component.scss'],
  animations: [fade]
})
export class MainProfileComponent implements OnInit, OnDestroy {

  form: FormGroup;
  background = 'day';

  private usersSubscriber: Subscription;

  constructor(
    private fb: FormBuilder,
    private usersService: UsersService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.usersSubscriber = this.usersService.getUserInfo().subscribe((user) => {
      this.form = this.fb.group({
        name: new FormControl(user.profile.name),
        surname: new FormControl(user.profile.surname),
        short: new FormControl(user.profile.short, Validators.required),
        genre: new FormControl(user.profile.genre, Validators.required),
        nickname: new FormControl(user.profile.nickname, [Validators.required, Validators.minLength(4)]),
        phoneNumber: new FormControl({ value: user.profile.phoneNumber, disabled: true }, Validators.required),
        email: new FormControl(user.profile.email, Validators.email)
      });
    });
  }

  ngOnDestroy() {
    this.usersSubscriber?.unsubscribe();
  }

  saveData() {
    this.usersService.save(this.form.value).subscribe((resp) => {
      if (resp.success) {
        this.messageService.add({
          key: 'document',
          severity: 'success',
          summary: 'Datos guardados',
          detail: 'Perfil actualizado correctamente.'
        });
      }
    });
  }

}
