<div class="p-grid content" *ngIf="release">
    <div class="p-lg-3 p-md-4 p-sm-12 p-col-12 left">
        <div class="image" [style.background-image]="release.cover ? 'url(' + release.cover + ')' : 'url(../../../assets/images/noimage.png)'"></div>
    </div>
    <div class="p-lg-9 p-md-8 p-sm-12 p-col-12 p-g-nopad right" data-simplebar>
        <h1>{{ release.programName }} {{ release.release }}</h1>
        <h2>{{ release.year }} • Q{{ release.quarter }}</h2>
        <!-- <button pButton type="button" label="Reproducir todo" class="p-button-secondary"></button> -->
        <button pButton *ngIf="video" type="button" label="Ver vídeo" class="p-button-secondary" (click)="videoModal = !videoModal"></button>
        <!-- <button *ngIf="admin && downloads.video" pButton type="button" label="Descargar vídeo" class="p-button-outlined p-button-secondary" (click)="getDownload('video', 'masterclass')"></button>
        <button *ngIf="downloads.music" pButton type="button" label="Descargar música" class="p-button-outlined p-button-secondary" (click)="getDownload('music', 'pack')"></button> -->
        <input *ngIf="admin" id="video" type="file" (change)="uploadVideo($event.target.files[0])">
        <input *ngIf="admin" id="music" type="file" (change)="uploadMusic($event.target.files[0])">
        <!-- <button pButton *ngIf="admin && !downloads.video" type="button" label="Añadir vídeo" class="p-button-secondary"  (click)="admin ? selectVideoFile() : null"></button>
        <button pButton *ngIf="admin && !downloads.music" type="button" label="Añadir música" class="p-button-secondary"  (click)="admin ? selectMusicFile() : null"></button> -->
        <button pButton *ngIf="downloadsMenu.length > 0" style="float:right" type="button" icon="pi pi-cloud-download" class="p-button-rounded p-button-secondary p-button" (click)="download.toggle($event)"></button>
        <p-menu #download [popup]="true" [model]="downloadsMenu" appendTo="body"></p-menu>
        <button pButton *ngIf="admin" type="button" icon="pi pi-ellipsis-v" class="p-button-rounded p-button-text p-button-secondary" (click)="menu.toggle($event)"></button>
        <p-menu #menu [popup]="true" [model]="adminMenu" appendTo="body"></p-menu>
        <div class="disc" *ngFor="let disc of playlist | keyvalue">
            <h3 *ngIf="disc.key !== 'unknown'">{{ disc.key }}</h3>
            <div class="songs">
                <div class="song" [ngClass]="{ file: song.file, selected: song.id === musicPlayer.currentTrack }" *ngFor="let song of disc.value">
                    <input *ngIf="admin" [id]="song.id" type="file" (change)="uploadSong($event.target.files[0], song.id)">
                    <div class="track" [musicPlayer]="'play'" [song]="song" [songs]="disc.value">
                        <div class="type" *ngIf="song.type !== 'original'">{{ song.type }}</div>
                        <div>
                            <span>{{ song.track }}</span>
                            <span>{{ song.letter }}</span>
                        </div>
                        <i class="mi filled" *ngIf="song.file">{{ song.id === musicPlayer.currentTrack && musicPlayer.isPlaying ? 'pause' : 'play_arrow' }}</i>
                    </div>
                    <div class="info" [musicPlayer]="'play'" [song]="song" [songs]="disc.value">
                        <span><b>{{ song.title }}</b></span>
                        <span class="grey">{{ song.artist }}</span>
                    </div>
                    <div class="time" (click)="admin ? selectSongFile(song.id) : null">
                        <span>{{ (song.duration || '0') | date: 'm:ss' }}</span>
                    </div>
                </div>
            </div>
            <h4 *ngIf="playlist">{{ disc.value.length }} canciones • 52 minutos</h4>
        </div>
    </div>
</div>

<p-dialog modal="true" [(visible)]="videoModal" *ngIf="video">
    <video controls>
        <source [src]="video.url" [type]="video.mimeType">
        Your browser does not support HTML video.
      </video>
</p-dialog>