import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { fade, mainLogo } from '../animations';
import { LoginService } from '../_services/login.service';
import { UsersService } from '../_services/users.service';
import { Menu } from '../_models/menu';
import { MenuItem } from 'primeng/api';
import { AppService } from '../_services/app.service';
import { LesmillsService } from '../_services/lesmills.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  animations: [fade, mainLogo]
})
export class MainComponent implements OnInit {

  loading = {
    document: false
  };
  user;

  menu: Menu[];
  sideMenu: MenuItem[] = [
    { label: 'BODYPUMP' }
  ];
  version;

  private lesmillsSubscriber: Subscription;

  constructor(
    private router: Router,
    private loginService: LoginService,
    private usersService: UsersService,
    private appService: AppService,
    private lesmillService: LesmillsService
  ) { }

  ngOnInit(): void {
    this.loading.document = true;
    this.loginService.auth().subscribe((user: firebase.User) => {
      this.usersService.loggedUser.uid = user.uid;
      this.usersService.getUserInfo().subscribe((u) => {
        if (u) {
          this.usersService.loggedUser = u;
          this.user = this.usersService.loggedUser;
          this.loading.document = false;
          // if (!u.profile.short || !u.profile.nickname || !u.profile.genre) {
          //   this.router.navigate(['profile']);
          // }
        }
      });
    }, () => {
      this.loading.document = false;
      this.router.navigate(['login']);
    });

    this.version = this.appService.version;

    // Suscripción para cambios en base de datos.
    this.lesmillsSubscriber = this.lesmillService.getPrograms().subscribe((resp) => {
      this.sideMenu = resp.map((program) => {
        return {
          label: program.name,
          routerLink: [program.code]
        }
      });
      this.lesmillsSubscriber.unsubscribe();
    });

    this.menu = [
      // { position: 'left', order: 1, name: 'Perfil', icon: 'face', page: '/profile' },
      // { position: 'left', order: 2, name: 'Contactos', icon: 'people', page: '/contacts' },
      // { position: 'left', order: 3, name: 'Chat', icon: 'mode_comment', page: '/chat' },
      // { position: 'right', order: 1, name: 'Calendario', icon: 'event', page: '/calendar' },
      // { position: 'right', order: 2, name: 'Notas', icon: 'note', page: '/notes' },
      // { position: 'right', order: 3, name: 'Tareas', icon: 'toc', page: '/tasks' },
      // { position: 'bottom', order: 1, name: 'Drive', icon: 'cloud', page: '/drive' },
      // { position: 'bottom', order: 2, name: 'Play', icon: 'tv', page: '/play' },
      // { position: 'bottom', order: 3, name: 'LesMills', icon: 'fitness_center', page: '/lesmills' }
    ];
  }

  logout() {
    this.loginService.singOut();
  }

}
