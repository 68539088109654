import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TooltipPositions } from '../../../_models/tooltip-positions.enum';
import { Tools } from 'src/app/_models/tools';

@Component({
  selector: 'app-left-content',
  templateUrl: './left-content.component.html',
  styleUrls: ['./left-content.component.scss']
})
export class LeftContentComponent implements OnInit {

  @Input() set tools(value) {
    this._tools = value;
    this.init();
  }
  @Output() action = new EventEmitter<string>();

  // tslint:disable-next-line: variable-name
  _tools: Tools[];

  options: {
    position: 'left' | 'right',
    tools: any,
    tooltip: TooltipPositions
  }[];

  constructor() { }

  ngOnInit(): void {
    this.init();
  }

  init() {
    if (this._tools && this._tools.length !== 0) {
      this.generateTools();
      setTimeout(() => {
        document.getElementsByClassName('simplebar-vertical')[0].classList.add('simplebar-maxheight');
        document.getElementsByClassName('simplebar-content')[0].classList.add('simplebar-content-padding');
      });
    }
  }

  actionClick(identifier) {
    this.action.emit(identifier);
  }

  filterTools(position: 'left' | 'right') {
    return this._tools.filter((f) => f.position === position).sort((a, b) => a.order > b.order ? 1 : -1);
  }

  generateTools() {
    const positions: any['left' | 'right'] = ['left', 'right'];
    const options = [];
    positions.forEach((position) => {
      if (this.filterTools(position).length !== 0) {
        options.push({ position, tools: this.filterTools(position), tooltip: TooltipPositions[position] });
      }
    });
    this.options = options;
  }

}
