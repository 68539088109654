<app-left-content [tools]="leftTools" (action)="toolsAction($event)">
    <div class="program" [ngClass]="{ actived: selectedProgram === program.code }" *ngFor="let program of programs" (click)="goProgram(program.code)">
        <span>{{ program.name }}</span>
        <i class="mi">chevron_right</i>
    </div>
</app-left-content>

<app-right-content>
    <router-outlet></router-outlet>

    <!-- HOME -->
    <div id="home" [@fade] *ngIf="selectedProgram === undefined">
        <img src="../../../assets/images/lesmills-logo.png">
    </div>
</app-right-content>

<p-dialog header="Crear programa"
          *ngIf="admin"
          [(visible)]="modal"
          [modal]="true"
          [style]="{width: '30vw'}"
          [draggable]="false"
          [closable]="false"
          [closeOnEscape]="false"
          [resizable]="false">
    <form [formGroup]="programForm" (ngSubmit)="createProgram()">
        <div class="p-grid p-fluid">
            <div class="p-col-12 p-md-4 p-lg-4 p-xl-4">
                <input type="text" pInputText formControlName="name" placeholder="Nombre">
            </div>
            <div class="p-col-12 p-md-4 p-lg-4 p-xl-4">
                <input type="text" pInputText formControlName="code" placeholder="Código">
            </div>
            <div class="p-col-12 p-md-4 p-lg-4 p-xl-4">
                <input type="text" pInputText formControlName="color" placeholder="Color">
            </div>
        </div>
    </form>
    <p-footer>
        <button type="button" pButton (click)="modal=false; programForm.reset();" label="Cancelar" class="p-button-secondary"></button>
        <button type="submit" pButton (click)="createProgram()" label="Crear" [disabled]="this.programForm.invalid"></button>
    </p-footer>
</p-dialog>