import {NgModule, ModuleWithProviders} from '@angular/core';

// NgPrime
import { ProgressBarModule } from 'primeng/progressbar';

// directives
import {ClearPlaylistDirective} from './clear-playlist.directive';
import {DecreaseVolumeDirective} from './decrease-volume.directive';
import {IncreaseVolumeDirective} from './increase-volume.directive';
import {MusicPlayerDirective} from './music-player.directive';
import {MuteMusicDirective} from './mute-music.directive';
import {NextTrackDirective} from './next-track.directive';
import {PauseMusicDirective} from './pause-music.directive';
import {PlayAllDirective} from './play-all.directive';
import {PlayFromPlaylistDirective} from './play-from-playlist.directive';
import {PlayMusicDirective} from './play-music.directive';
import {PreviousTrackDirective} from './previous-track.directive';
import {RemoveFromPlaylistDirective} from './remove-from-playlist.directive';
import {RepeatMusicDirective} from './repeat-music.directive';
import {StopMusicDirective} from './stop-music.directive';
import {ShuffleMusicDirective} from './shuffle-music.directive';
import {VolumeBarDirective} from './volume-bar.directive';
import {ProgressBarDirective} from './progress-bar.directive';

// pipes
import {HumanTimePipe} from './human-time.pipe';

// services
import {MusicPlayerService} from './music-player.service';
import { PlayPauseMusicDirective } from './play-pause.directive';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';

@NgModule({
    declarations: [
        // Directives
        ClearPlaylistDirective,
        DecreaseVolumeDirective,
        IncreaseVolumeDirective,
        MusicPlayerDirective,
        MuteMusicDirective,
        NextTrackDirective,
        PauseMusicDirective,
        PlayAllDirective,
        PlayFromPlaylistDirective,
        PlayMusicDirective,
        PlayPauseMusicDirective,
        PreviousTrackDirective,
        RemoveFromPlaylistDirective,
        RepeatMusicDirective,
        ShuffleMusicDirective,
        StopMusicDirective,
        VolumeBarDirective,
        ProgressBarDirective,
        ProgressBarComponent,

        // Pipes
        HumanTimePipe
    ],
    imports: [
        ProgressBarModule
    ],
    exports: [
        // Directives
        ClearPlaylistDirective,
        DecreaseVolumeDirective,
        IncreaseVolumeDirective,
        MusicPlayerDirective,
        MuteMusicDirective,
        NextTrackDirective,
        PauseMusicDirective,
        PlayAllDirective,
        PlayFromPlaylistDirective,
        PlayMusicDirective,
        PlayPauseMusicDirective,
        PreviousTrackDirective,
        RemoveFromPlaylistDirective,
        RepeatMusicDirective,
        ShuffleMusicDirective,
        StopMusicDirective,
        VolumeBarDirective,
        ProgressBarDirective,
        ProgressBarComponent,

        // Pipes
        HumanTimePipe
    ]
})
export class MusicModule {
    constructor() {
    }

    static forRoot(): ModuleWithProviders<MusicModule> {
        return {
            ngModule: MusicModule,
            providers: [
                MusicPlayerService
            ]
        };
    }
}
