// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: 'AIzaSyALjKvC095ew1c8SsLl4s0FXMdlPeHNu3I',
    authDomain: 'togedapp.firebaseapp.com',
    databaseURL: 'https://togedapp.firebaseio.com',
    projectId: 'togedapp',
    storageBucket: 'togedapp.appspot.com',
    messagingSenderId: '591607599256',
    appId: '1:591607599256:web:afcc644b8d0d31603ac0ed',
    measurementId: 'G-WN0RDDYYPM'
  },
  firebase_messaging: 'BCMT9QYd3u0M1ckZ8LBF1l8xfNHfaaHQZshmlHH4LPAGDZNGLdOlSIAauyQTuaf039i9DX7Vl94vkWWcnLbmRq8',
  aws: {
    accessKeyId: 'AKIAWJGZTTJAYDDSTZ57',
    secretAccessKey: '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',
    bucket: 'data.togedapp.com',
    bucketUrl: 'https://data.togedapp.com/'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
