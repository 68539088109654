import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import 'firebase/database';
import { Observable } from 'rxjs';
import { User } from '../_models/user';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  loggedUser: User = { uid: undefined, profile: undefined };

  constructor() { }

  /**
   * Devuelve la información del usuario logado.
   */
  getUserInfo(): Observable<User> {
    return new Observable((observer) => {
      firebase.database().ref().child('users').child(this.loggedUser.uid).on('value', (user) => {
        observer.next({ uid: user.key, ...user.val() });
      });
    });
  }

  /**
   * Devuelve la información del usuario con UID informado.
   * @param uid UID del usuario que se quiere consultar.
   */
  getUser(uid): Observable<any> {
    return new Observable((observer) => {
      firebase.database().ref().child('users').child(uid).once('value', (user) => {
        observer.next({ uid: user.key, ...user.val() });
        observer.complete();
      });
    });
  }

  /**
   * Devuelve la lista de todos los usuarios y su información.
   */
  getAllUsers(): Observable<any> {
    return new Observable((observer) => {
      firebase.database().ref().child('users').on('value', (user) => {
        const users = [];
        user.forEach((u) => {
          users.push({ uid: u.key, ...u.val() });
        });
        observer.next(users);
      });
    });
  }

  /**
   * Guarda el token del dispositivo para su uso con web push notifications.
   * @param token Token del dispositivo actual.
   */
  saveMessagingToken(token) {
    firebase.database().ref().child('users').child(this.loggedUser.uid).once('value', (user) => {
      if (user.val().messaging) {
        const tokens = user.val().messaging;
        const exists = tokens.find((f) => f === token);
        if (!exists) {
          tokens.push(token);
          firebase.database().ref().child('users').child(this.loggedUser.uid).update({ messaging: tokens });
        }
      } else {
        firebase.database().ref().child('users').child(this.loggedUser.uid).update({ messaging: [token] });
      }
    });
  }

  /**
   * Guarda los cambios del perfil de usuario.
   * @param data Datos a guardar.
   */
  save(data): Observable<any> {
    return new Observable((observer) => {
      firebase.database().ref().child('users').child(this.loggedUser.uid).child('profile').update(data).then((resp) => {
        observer.next({ success: true });
        observer.complete();
      });
    });
  }
}
