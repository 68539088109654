<p-toast position="top-right" key="document" position="bottom-right"></p-toast>
<div class="logo" *ngIf="!loading.document" [@mainLogo]>lesmills</div>
<div class="logo-small" *ngIf="!loading.document" (click)="menu.toggle($event)">lm</div>
<p-menu #menu [popup]="true" [model]="sideMenu"></p-menu>
<div *ngIf="!loading.document" [@fade]>
    <app-music-player></app-music-player>
    <div class="toolbar">
        <div class="user-menu">
            <div class="user">{{ user?.profile?.short || user?.profile?.phoneNumber }}</div>
            <div class="button" (click)="logout()" pTooltip="Cerrar sesión" tooltipPosition="left">
                <i class="mi">power_settings_new</i>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="content">
            <router-outlet></router-outlet>
        </div>
    </div>
    <div class="footer">© 2012-2021 LMPW - {{version}}</div>
    <app-menu *ngIf="user?.profile?.short && user?.profile?.nickname && user?.profile?.genre" [menu]="menu" [@fade]></app-menu>
</div>

<p-blockUI [blocked]="loading.document">
    <p-progressSpinner  [style]="{width: '50px', height: '50px'}" strokeWidth="5"></p-progressSpinner>
</p-blockUI>