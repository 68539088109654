<div class="container">
    <form [formGroup]="releaseForm" (ngSubmit)="sendRelease()">
        <div class="p-grid content">
            <div class="p-lg-3 left">
                <input id="cover" type="file" (change)="prepareCover($event.target.files[0])">
                <div class="image" (click)="onClickCover()"></div>
            </div>
            <div class="p-lg-9 p-g-nopad right" data-simplebar>
                <div class="p-grid p-fluid">
                    <!-- Basic form -->
                    <div class="p-lg-12">
                        <h2>Información</h2>
                    </div>
                    <div class="p-lg-4">
                        <p-dropdown [options]="programs" formControlName="program" placeholder="Programa" optionLabel="name"></p-dropdown>
                    </div>
                    <div class="p-lg-2">
                        <p-inputNumber formControlName="release" placeholder="Release" [min]="0" [max]="201"></p-inputNumber>
                    </div>
                    <div class="p-lg-2">
                        <p-dropdown [options]="years" formControlName="year" placeholder="Año"></p-dropdown>
                    </div>
                    <div class="p-lg-4">
                        <p-dropdown [options]="quarters" formControlName="quarter" placeholder="Trimestre"></p-dropdown>
                    </div>

                    <!-- Playlist -->
                    <div class="p-lg-12" [@fade] *ngIf="releaseForm.controls.program.value">
                        <h2>Playlist</h2>
                        <p *ngIf="releaseForm.controls.playlist.value.length === 0">La playlist está vacía. Añade canciones con el formulario inferior.</p>
                        <ng-container *ngIf="releaseForm.controls.playlist.value.length > 0">
                            <div class="disc" *ngFor="let disc of playlist | keyvalue">
                                <h3 *ngIf="disc.key !== 'unknown'">{{ disc.key }}</h3>
                                <div class="songs">
                                    <div class="song" *ngFor="let song of disc.value">
                                        <div class="track">
                                            <div class="type" *ngIf="song.type !== 'original'">{{ song.type }}</div>
                                            <div>
                                                <span>{{ song.track }}</span>
                                                <span>{{ song.letter }}</span>
                                            </div>
                                        </div>
                                        <div class="info">
                                            <span><b>{{ song.title }}</b></span>
                                            <span class="grey">{{ song.artist }}</span>
                                        </div>
                                        <div class="time">
                                            <span>{{ (song.duration || '0') | date: 'm:ss' }}</span>
                                        </div>
                                    </div>
                                </div>
                                <h4>{{ disc.value.length }} canciones</h4>
                            </div>
                        </ng-container>
                    </div>
                    <form [formGroup]="playlistForm" (ngSubmit)="addSong()" [@fade] *ngIf="releaseForm.controls.program.value">
                        <div class="p-grid p-fluid">
                            <div class="p-lg-3">
                                <p-inputNumber placeholder="Track" formControlName="track" [min]="0" [max]="100"></p-inputNumber>
                            </div>
                            <div class="p-lg-3">
                                <p-dropdown [options]="trackLetter" formControlName="letter"></p-dropdown>
                            </div>
                            <div class="p-lg-3">
                                <p-dropdown [options]="trackType" formControlName="type"></p-dropdown>
                            </div>
                            <div class="p-lg-3">
                                <input type="text" pInputText formControlName="disc" placeholder="Disco" autocomplete="off">
                            </div>
                            <div class="p-lg-6">
                                <input type="text" pInputText formControlName="title" placeholder="Título" autocomplete="off">
                            </div>
                            <div class="p-lg-6">
                                <div class="p-inputgroup">
                                    <input type="text" pInputText formControlName="artist" placeholder="Artista" autocomplete="off">
                                    <button pButton type="submit" icon="pi pi-plus" [disabled]="playlistForm.invalid"></button>    
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="form-submit">
            <button pButton type="submit" label="Crear" [disabled]="releaseForm.invalid"></button>
        </div>
    </form>
</div>