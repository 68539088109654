import { Pipe, PipeTransform } from '@angular/core';
import { LesmillsPlaylistSong } from '../_models/lesmills-playlist-song';
import { KeyValuePipe } from '@angular/common';

@Pipe({
  name: 'lesmillsPlaylist'
})
export class LesmillsPlaylistPipe implements PipeTransform {

  constructor(
    private keyvaluePipe: KeyValuePipe
  ) {

  }

  transform(array): LesmillsPlaylistSong[] {

    const playlist: { key: string, value: LesmillsPlaylistSong }[] = this.keyvaluePipe.transform(array);

    const pl: LesmillsPlaylistSong[] = [];

    playlist.forEach((song) => {
      const s = song.value;

      s.key = song.key;

      const more = playlist.filter((f) => f.value.track === s.track && (f.value.letter !== s.letter && f.value.letter !== undefined));

      // Si no hay más tracks con otras letras, la letra no se muestra.
      if (more.length === 0) {
        s.letter = undefined;
      }

      pl.sort((a, b) => {
        if (a.track > b.track) return 1;
        if (a.track > b.track) return -1;
        if (a.letter > b.letter) return 1;
        if (a.letter < b.letter) return -1;
        // if (a.type === 'original') return 1;
        // if (a.type > b.type) return 1;
        // if (a.type < b.type) return -1;
        return 0;
      });

      pl.push(s);
    });

    return pl;
  }

}
