<ng-container *ngFor="let option of options">
    <div class="balloon-menu" [ngClass]="option.position">
        <div class="content" [ngClass]="option.position">
            <div *ngFor="let menu of option.menu; let i = index"
                 class="balloon"
                 [ngClass]="option.position"
                 [class.actived]="page === menu.page"
                 [pTooltip]="menu.name"
                 [routerLink]="menu.page"
                 [tooltipPosition]="option.tooltip"
                 [style.transition-delay]="i * 50 + 'ms'">
                <i class="mi">{{ menu.icon }}</i>
            </div>
        </div>
    </div>
</ng-container>

<ng-container>
    <div class="collapsed-menu">
        <div class="content">
            <i class="mi">menu</i>
        </div>
    </div>
</ng-container>
