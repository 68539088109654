import { Directive, HostListener } from '@angular/core';
import { MusicPlayerService } from './music-player.service';

@Directive({
  selector: '[playPauseMusic]'
})
export class PlayPauseMusicDirective {

  constructor(private _musicPlayerService: MusicPlayerService) {}

  @HostListener('click', ['$event']) onClick() {
    if (this._musicPlayerService.isPlaying) {
        this._musicPlayerService.pause();
    } else {
        this._musicPlayerService.play();
    }
  }
}
