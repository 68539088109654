import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { environment } from '../environments/environment';

// Animations
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Firebase
import * as firebase from 'firebase/app';
import 'firebase/analytics';

// NgPrime Modules
import { PanelModule } from 'primeng/panel';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { KeyFilterModule } from 'primeng/keyfilter';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { ProgressBarModule } from 'primeng/progressbar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { BlockUIModule } from 'primeng/blockui';
import { ToastModule } from 'primeng/toast';
import { ToolbarModule } from 'primeng/toolbar';
import { InputMaskModule } from 'primeng/inputmask';
import { DialogModule } from 'primeng/dialog';
import { TooltipModule } from 'primeng/tooltip';
import { SelectButtonModule } from 'primeng/selectbutton';
import { InputNumberModule } from 'primeng/inputnumber';
import { MenuModule } from 'primeng/menu';
import { ScrollPanelModule } from 'primeng/scrollpanel';

// NgPrime Services
import { MessageService } from 'primeng/api';

// Router
import { AppRoutingModule } from './app-routing.module';

// Music player
import { MusicModule } from './_utils/music/music.module';

// App Components
import { AppComponent } from './app.component';
import { MainComponent } from './main/main.component';

// App Services
import { UsersService } from './_services/users.service';
import { DatePipe } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { LoginService } from './_services/login.service';
import { MainProfileComponent } from './main/main-profile/main-profile.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MenuComponent } from './_layout/menu/menu.component';
import { LeftContentComponent } from './main/_layout/left-content/left-content.component';
import { RightContentComponent } from './main/_layout/right-content/right-content.component';
import { MainLesmillsComponent } from './main/main-lesmills/main-lesmills.component';
import { LesmillsService } from './_services/lesmills.service';
import { LesmillsPlaylistPipe } from './_pipes/lesmills-playlist.pipe';
import { MusicPlayerComponent } from './main/_layout/music-player/music-player.component';
import { MainLesmillsProgramComponent } from './main/main-lesmills/main-lesmills-program/main-lesmills-program.component';
import { MainLesmillsReleaseComponent } from './main/main-lesmills/main-lesmills-release/main-lesmills-release.component';
import { MainLesmillsAdminComponent } from './main/main-lesmills/main-lesmills-admin/main-lesmills-admin.component';
import { MainLesmillsAdminAddreleaseComponent } from './main/main-lesmills/main-lesmills-admin/main-lesmills-admin-addrelease/main-lesmills-admin-addrelease.component';
import { SoonComponent } from './soon/soon.component';

// Firebase init
firebase.initializeApp(environment.firebaseConfig);
firebase.analytics();

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    LoginComponent,
    MainProfileComponent,
    MenuComponent,
    LeftContentComponent,
    RightContentComponent,
    MainLesmillsComponent,
    LesmillsPlaylistPipe,
    MusicPlayerComponent,
    MainLesmillsProgramComponent,
    MainLesmillsReleaseComponent,
    MainLesmillsAdminComponent,
    MainLesmillsAdminAddreleaseComponent,
    SoonComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    MusicModule.forRoot(),
    PanelModule,
    InputTextModule,
    DropdownModule,
    MultiSelectModule,
    InputTextareaModule,
    KeyFilterModule,
    AutoCompleteModule,
    ButtonModule,
    MessagesModule,
    MessageModule,
    ProgressBarModule,
    ProgressSpinnerModule,
    BlockUIModule,
    ToastModule,
    ToolbarModule,
    InputMaskModule,
    DialogModule,
    TooltipModule,
    SelectButtonModule,
    InputNumberModule,
    MenuModule,
    ScrollPanelModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    MessageService,
    UsersService,
    LoginService,
    LesmillsService,
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
