import { Component, OnInit, OnDestroy } from '@angular/core';
import { MusicPlayerService } from 'src/app/_utils/music/music-player.service';
import { Subscription } from 'rxjs';
import { musicPlayer } from 'src/app/animations';

@Component({
  selector: 'app-music-player',
  templateUrl: './music-player.component.html',
  styleUrls: ['./music-player.component.scss'],
  animations: [musicPlayer]
})
export class MusicPlayerComponent implements OnInit, OnDestroy {

  mute: boolean;

  currentPlaying: any;

  currentTrackPostion: number;
  currentTrackDuration: number;
  currentTrackProgress: number;
  currentTrackLoaded: number;
  volume: number;

  // subscriptions
  private _musicPlayerMuteSubscription: Subscription;
  private _musicPlayerTrackIdSubscription: Subscription;
  private _musicPlayerVolumeSubscription: Subscription;

  constructor(
    public _musicPlayerService: MusicPlayerService
  ) { }

  get progress(): number {
    return this.currentTrackProgress;
  }

  get loaded(): number {
    return this.currentTrackLoaded;
  }

  get loading(): string {
    return this.currentTrackProgress === 0 ? 'indeterminate' : 'determinate';
  }

  get playlist(): any {
    return this._musicPlayerService.getPlaylist();
  }

  get isPlaying(): boolean {
    return this._musicPlayerService.isPlaying;
  }

  ngOnInit(): void {
    // Subscribe for mute changes to update bindings
    this.mute = this._musicPlayerService.getMuteStatus();
    this._musicPlayerMuteSubscription = this._musicPlayerService.musicPlayerMuteEventEmitter
      .subscribe((event: any) => {
        this.mute = event.data;
      });

    // Subscribe for track changes
    this.currentPlaying = this._musicPlayerService.currentTrackData();
    this._musicPlayerTrackIdSubscription = this._musicPlayerService.musicPlayerTrackEventEmitter
      .subscribe((event: any) => {
        this.currentPlaying = this._musicPlayerService.currentTrackData();
        this.currentTrackPostion = event.data.trackPosition || 0;
        this.currentTrackDuration = event.data.trackDuration || 0;
        this.currentTrackProgress = event.data.trackProgress || 0;
        this.currentTrackLoaded = event.data.trackLoaded || 0;
      });

    // subscribe for volume changes
    this.volume = this._musicPlayerService.getVolume();
    this._musicPlayerVolumeSubscription = this._musicPlayerService.musicPlayerVolumeEventEmitter
      .subscribe((event: any) => {
        this.volume = event.data;
      });
  }

  ngOnDestroy() {
    this._musicPlayerMuteSubscription?.unsubscribe();
    this._musicPlayerTrackIdSubscription.unsubscribe();
    this._musicPlayerVolumeSubscription.unsubscribe();
  }

}
