import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as firebase from 'firebase/app';
import 'firebase/database';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  version = "7.1.0beta";

  constructor() { }

  dayMode(): Observable<any> {
    return new Observable((observer) => {
      observer.next(this.getDayMode());
      setInterval(() => {
        observer.next(this.getDayMode());
      }, 60000);
    });
  }

  getConfig(): Observable<any> {
    return new Observable((observer) => {
      firebase.database().ref().child('config').child('lesmills').on('value', (config) => {
        observer.next(config.val());
      });
    });
  }

  private getDayMode(): 'day' | 'night' {
    if (new Date().getHours() >= 21 || new Date().getHours() <= 8) {
      return 'night';
    } else {
      return 'day';
    }
  }
}
