import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './main/main.component';
import { LoginComponent } from './login/login.component';
import { MainProfileComponent } from './main/main-profile/main-profile.component';
import { MainLesmillsComponent } from './main/main-lesmills/main-lesmills.component';
import { MainLesmillsProgramComponent } from './main/main-lesmills/main-lesmills-program/main-lesmills-program.component';
import { MainLesmillsReleaseComponent } from './main/main-lesmills/main-lesmills-release/main-lesmills-release.component';
import { MainLesmillsAdminAddreleaseComponent } from './main/main-lesmills/main-lesmills-admin/main-lesmills-admin-addrelease/main-lesmills-admin-addrelease.component';
import { MainLesmillsAdminComponent } from './main/main-lesmills/main-lesmills-admin/main-lesmills-admin.component';
import { SoonComponent } from './soon/soon.component';


const routes: Routes = [
  { path: 'soon', component: SoonComponent },
  { path: 'login', component: LoginComponent },
  { path: '', component: MainComponent, children: [
    // { path: 'profile', component: MainProfileComponent },
    { path: '', component: MainLesmillsComponent, children: [
      { path: 'admin', component: MainLesmillsAdminComponent, children: [
        { path: '', redirectTo: 'addrelease', pathMatch: 'full' },
        { path: 'addrelease', component: MainLesmillsAdminAddreleaseComponent },
        { path: '**', redirectTo: 'addrelease', pathMatch: 'full' }
      ]},
      { path: ':program', component: MainLesmillsProgramComponent },
      { path: ':program/:release', component: MainLesmillsReleaseComponent }
    ]}
  ]},
  { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
