import { Component, OnInit, HostListener, Input } from '@angular/core';
import { MusicPlayerService } from '../music-player.service';

@Component({
  selector: 'music-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit {

  protected currentPlaying: any;
  protected currentTrackPostion: any;
  protected currentTrackDuration: any;
  protected currentTrackProgress: any;

  constructor(
    private _musicPlayerService: MusicPlayerService
  ) { }

  @Input() color: string;
  @Input() loading: string;
  @Input() progress: string;
  @Input() loaded: string;

  @HostListener('click', ['$event']) onClick($event) {
    const getXOffset = ((event) => {

        let x = 0;
        let element = event.target;
        while (element && !isNaN(element.offsetLeft) && !isNaN(element.offsetTop)) {
            x += element.offsetLeft - element.scrollLeft;
            element = element.offsetParent;
        }
        return event.clientX - x;
    });
    const x = $event.ClientX || getXOffset($event);
    const width = $event.target.clientWidth;
    const duration = this._musicPlayerService.duration;
    const progress = (x / width) * duration;
    this._musicPlayerService.adjustProgress(progress);
  }

  ngOnInit() {
  }

}
